import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import 'uikit/dist/css/uikit.css'
import SecondaryLayout from "../layouts/secondary-layout"
import GetImgUrl from "../utils/customFunctions"

import Header from "../components/header/header"
import ContactComponent from "../components/contact-component/contact-component"
import TextImageComponent from "../components/text-image-component/text-image-component"
import Paragraph from "../components/paragraph/paragraph"
// import CTAList from "../components/cta-list/cta-list"






// markup
const MenuPage = ({ pageContext }) => {

    const data = useStaticQuery(graphql`
        {
          strapiMenuIndex {
            PageComponents
            Seo {
                metaDescription
                metaTitle
              }
          }
          allStrapiSubmenuTypes {
            edges {
              node {
                Slug
              }
            }
          }
          
        }
        `
    )

    // var submenu
    // data.allStrapiSubmenuTypes.edges.map((item, index) => {
    //     return submenu = item.node.Slug
    // })



    return (
        <SecondaryLayout SEOTitle={data.strapiMenuIndex.Seo.metaTitle} SEODescription={data.strapiMenuIndex.Seo.metaDescription}>

            {data.strapiMenuIndex?.PageComponents?.map((item, index) => {


                switch (item.strapi_component) {

                    case "page-components.header":
                        return (
                            <>

                                <Header
                                    key={index}
                                    Title={item.Title}
                                    Subtitle={item.Subtitle}
                                    FirstCTATitle={item.FirstCTATitle}
                                    FirstCTAURL={item.FirstCTAUrl}
                                    SecondCTATitle={item.SecondCTATitle}
                                    SecondCTAURL={item.SecondCTAUrl}
                                    ThirdCTATitle={item.ThirdCTATitle}
                                    ThirdCTAURL={item.ThirdCTAUrl}
                                    Background={GetImgUrl(item.Background?.localFile___NODE, pageContext.images)}
                                    Icon={item.Icon !== null ? GetImgUrl(item.Icon.localFile___NODE, pageContext.images) : null}
                                    HeaderHeight={item.HeaderHeight}
                                    FooterInformation={item.FooterInformation !== null ? item.FooterInformation : null}
                                ></Header>
                            </>

                        )

                    // case "page-components.menu-cards":
                    //     var contentsList = []
                    //     item.Cards.map((CardsItem, index) => {
                    //         var imageUrl = CardsItem.Image !== null ? GetImgUrl(CardsItem.Image.localFile___NODE, pageContext.images) : ""
                    //         contentsList.push({
                    //             Image: imageUrl,
                    //             Name: CardsItem.Name,
                    //             Slug: CardsItem.Slug,

                    //         })
                    //         return ("")
                    //     })
                    //     return (

                    //         <CTAList
                    //             key={index}
                    //             contents={contentsList}
                    //         ></CTAList>
                    //     )

                    case "page-components.text-image-component":
                        return (
                            <>

                                <TextImageComponent
                                    Image={item.Image !== null ? GetImgUrl(item.Image.localFile___NODE, pageContext.images) : null}
                                    ImagePosition={item.ImagePosition}
                                    Title={item.Title}
                                    Description={item.Description}
                                    Button={item.Button} />
                            </>
                        )

                    case "page-components.paragraph":
                        return (

                            <Paragraph
                                key={index}
                                Title={item.Title ? item.Title : ""}
                                Text={item.Text}
                                TopPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                BottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                            ></Paragraph>


                        )


                    case "page-components.contact-component":
                        return (
                            <>

                                <ContactComponent
                                    Title={item.Title}
                                    Address={item.Address}
                                    HTML={item.HTML} />
                            </>
                        )


                    default:
                        return ("")
                }

            })}


        </SecondaryLayout>
    )
}

export default MenuPage